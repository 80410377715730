import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import Layout from './Layout';
import './LinkValidator.css';
import Idvector from '../assets/IdVector.png';
import selfie from '../assets/selfieVector.jpg';
import Webcam from 'react-webcam';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faFolderOpen, faTimes, faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import {useNavigate} from 'react-router-dom';

const VERIFY_URI = gql`
  mutation VerifyUri($input: iVerifyUri!) {
    verifyUri(input: $input) {
      user {
        id
        firstName
        lastName
      }
      attemptId
      token
    }
  }
`;

const UPLOAD_VERIFICATION = gql`
  mutation UploadVerification($input: iUploadVerification!) {
    uploadVerification(input: $input)
  }
`;

const LinkValidator = () => {
  const linkId = useParams().linkId;
  const [userData, setUserData] = useState(null);
  const [loadingUri, setLoadingUri] = useState(true); // Loading state for URI verification
  const [loadingUpload, setLoadingUpload] = useState(false); // Loading state for upload verification
  const [error, setError] = useState(false);
  const [uriVerified, setUriVerified] = useState(false); // Success state for URI verification
  const [uploadVerified, setUploadVerified] = useState(false); // Success state for upload verification
  const [authToken, setAuthToken] = useState(null);
  const navigate = useNavigate();

  const [verifyUri] = useMutation(VERIFY_URI);
  const [uploadVerification] = useMutation(UPLOAD_VERIFICATION);

  useEffect(() => {
    const checkLink = async () => {
      if (!linkId) {
        setError(true);
        setLoadingUri(false);
        return;
      }
      
      const MIN_LOADING_TIME = 3000;
      const startTime = Date.now();

      try {
        const { data } = await verifyUri({
          variables: { input: { eventUri: linkId } },
        });

        if (data && data.verifyUri) {
          const { user, attemptId, token } = data.verifyUri;
          setUserData({ ...user, attemptId });
          setAuthToken(token);
          localStorage.setItem('authToken', token);
          setTimeout(() => setUriVerified(true), 1500);
        } else {
          setError(true);
        }
      } catch (error) {
        console.error('Error validating link:', error);
        setError(true);
      } finally {
        const elapsedTime = Date.now() - startTime;
        setTimeout(() => setLoadingUri(false), Math.max(MIN_LOADING_TIME - elapsedTime, 2000));
      }
    };

    checkLink();
  }, [linkId, verifyUri]);

  const handleImageSubmission = async (images) => {
    setLoadingUpload(true); // Start loading for upload
    setUploadVerified(false); // Ensure the spinner starts without success color

    try {
      const input = {
        idDocument: images.image1,
        photo: images.image2,
        attemptId: userData.attemptId,
      };

      const { data } = await uploadVerification({
        variables: { input },
        context: {
          headers: {
            Authorization: `Bearer ${authToken}`,
            'x-apollo-operation-name': 'UploadVerification',
            'apollo-require-preflight': 'true',
          },
        },
        operationName: 'UploadVerification',
      });

      if (data) {
        setTimeout(() => {
          setUploadVerified(true); // Change spinner to green after initial display
          setTimeout(() => {
            setLoadingUpload(false); // Hide upload spinner
            navigate('/home'); // Redirect to the home page
          }, 1500); // Display green spinner for 1.5 seconds before redirect
        }, 1500); // Display normal spinner for 1.5 seconds before turning green
      } else {
        alert('Verification Failed. Please try again.');
        setLoadingUpload(false); // Stop loading after alert
      }
    } catch (error) {
      alert('Verification Failed. \n' + error.message);
      setLoadingUpload(false); // Stop loading after alert
    }
  };
  
  if (loadingUri) {
    return (
      <div className="loading-spinner">
        <div className={`spinner ${uriVerified ? 'success' : ''}`}></div>
      </div>
    );
  }

  if (loadingUpload) {
    return (
      <div className="loading-spinner">
        <div className={`spinner ${uploadVerified ? 'success' : ''}`}></div>
      </div>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="error-message">
          <h1>Invalid Link</h1>
          <p>The link you have entered is not valid. Please check the URL and try again.</p>
          <p>If you believe this is an error, please contact support for further assistance.</p>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div>
        <h1>Hi {userData?.firstName}</h1>
        <ImageUploadForm onSubmitImages={handleImageSubmission} />
      </div>
    </Layout>
  );
};


const ImageUploadForm = ({ onSubmitImages, setIsVerified, isVerified }) => {
  const [images, setImages] = useState({
    image1: null,
    image2: null,
  });
  const [previews, setPreviews] = useState({
    preview1: Idvector,
    preview2: selfie,
  });
  const [step, setStep] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [useCamera, setUseCamera] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false); // Loading state for upload
  const [resultMessage, setResultMessage] = useState(''); // Result message state

  const openModal = () => setShowModal(true);
  const closeModal = () => {
    setShowModal(false);
    setUseCamera(false);
  };

  const handleImageChange = (e) => {
    const { files } = e.target;
    const file = files[0];
    if (file) {
      const imageName = step === 1 ? 'image2' : 'image1';
      setImages((prevImages) => ({ ...prevImages, [imageName]: file }));
      setPreviews((prevPreviews) => ({
        ...prevPreviews,
        [`preview${step}`]: URL.createObjectURL(file),
      }));
      closeModal();
    }
  };

  const handleCapture = (imageSrc) => {
    const imageName = step === 1 ? 'image2' : 'image1';
    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], `${imageName}.jpg`, { type: 'image/jpeg' });
        setImages((prevImages) => ({ ...prevImages, [imageName]: file }));
        setPreviews((prevPreviews) => ({
          ...prevPreviews,
          [`preview${step}`]: imageSrc,
        }));
        closeModal();
      });
  };

  const handleNextStep = () => {
    if (step === 1 && images.image2) {
      setStep(2);
    } else if (step === 2 && images.image1) {
      setUploadLoading(true); // Start loading
      onSubmitImages(images)
        .then(() => {
          setResultMessage('Verification Successful!');
          setIsVerified(true); // Set verified status on success
        })
        .catch(() => {
          setResultMessage('Verification Failed. Please try again.');
        })
        .finally(() => setUploadLoading(false)); // End loading
    } else {
      alert('Please upload the required image before proceeding.');
    }
  };

  if (uploadLoading) {
    return (
      <div className="loading-spinner">
        <div className={`spinner ${isVerified ? 'success' : ''}`}></div>
      </div>
    );
  }
  


  return (
    <div className="image-upload-form">
      {resultMessage && <div className="result-message">{resultMessage}</div>}
{showModal && (
  <div className="modal-overlay" onClick={closeModal}>
<div className="modal-content" onClick={(e) => e.stopPropagation()}>
  <h2>Select Image Source</h2>


      {useCamera ? (
        <Webcam
          audio={false}
          screenshotFormat="image/jpeg"
          className="webcam-preview"
        >
          {({ getScreenshot }) => (
            <button
              type="button"
              className="capture-button"
              onClick={() => handleCapture(getScreenshot(), `image${step}`)}
            >
              <FontAwesomeIcon icon={faCamera} /> Capture Photo
            </button>
          )}
        </Webcam>
      ) : (
        <>
          <button
            className="modal-option-button"
            onClick={() => document.getElementById(`image${step}`).click()}
          >
            <FontAwesomeIcon icon={faFolderOpen} /> Choose from Library
          </button>
          <input
            type="file"
            name={`image${step}`}
            id={`image${step}`}
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
        </>
      )}

      <button
        className="modal-option-button"
        onClick={() => setUseCamera(!useCamera)}
      >
        {useCamera ? (
          <>
            <FontAwesomeIcon icon={faFolderOpen} /> Choose from Library
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faCamera} /> Use Camera
          </>
        )}
      </button>

      <button onClick={closeModal} className="close-modal-button">
        <FontAwesomeIcon icon={faTimes} /> Cancel
      </button>
    </div>
  </div>
)}


      <div className="image-upload-step">
        <p>Please upload a {step === 1 ? 'selfie' : 'valid ID'} to continue.</p>
        <div className="image-preview-wrapper">

          
            <div>
              <img
                src={previews[`preview${step}`]}
                alt={`Preview ${step === 1 ? 'Selfie' : 'ID'}`}
                className="preview-img"
              />
             <button type="button" className="custom-file-upload" onClick={openModal}>
  <FontAwesomeIcon icon={faFolderOpen} /> Upload {step === 1 ? 'Selfie' : 'ID'}
</button>
              <input
                type="file"
                name={`image${step}`}
                id={`image${step}`}
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: 'none' }}
              />
            </div>
        </div>
        <button type="button" className="next-step-button" onClick={handleNextStep}>
  {step === 1 ? (
    <>
      <FontAwesomeIcon icon={faArrowRight} /> Next Step
    </>
  ) : (
    <>
      <FontAwesomeIcon icon={faCheck} /> Submit Images
    </>
  )}
</button>
      </div>
    </div>
  );
};

export default LinkValidator;
