import React, { createContext, useContext, useEffect, useState } from 'react';
import { useMutation, gql } from '@apollo/client';

// Create the context
const AuthContext = createContext();


const AUTH_USER_MUTATION = gql`
  mutation AuthUser($input: iAuthUser!) {
    authUser(input: $input) {
      token
      user {
        firstName
        preferredName
        lastName
        email
      }
    }
  }
`;


// AuthProvider component to wrap around parts of your app that need access to the authentication state
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [authUser] = useMutation(AUTH_USER_MUTATION);



  const login = async ({ email, password }) => {
    try {
      const { data } = await authUser({
        variables: {
          input: { email, password },
        },
      });

      if (data && data.authUser) {
        const { token, user } = data.authUser;

        // Set authentication state and save token and user to localStorage
        setIsAuthenticated(true);
        setUser(user);
        localStorage.setItem('authenticatedUser', JSON.stringify(user));
        localStorage.setItem('authToken', token);
      } else {
        throw new Error('Authentication failed.');
      }
    } catch (error) {
      console.error("Error during login:", error);
      throw new Error(error.message);
    }
  };

  // Initialize the auth state by checking if the user is already authenticated (from localStorage)
  useEffect(() => {
    const savedUser = JSON.parse(localStorage.getItem('authenticatedUser'));
    const savedToken = localStorage.getItem('authToken');

    if (savedUser && savedToken) {
      setIsAuthenticated(true);
      setUser(savedUser);
    }
  }, []);

  // Method to log out the user
  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem('authenticatedUser');
    localStorage.removeItem('authToken');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => {
  return useContext(AuthContext);
};
