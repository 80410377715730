import React from 'react';
import { Navigate } from 'react-router-dom';
// import { useAuth } from '../context/AuthContext';

const ProtectedRoute = ({ element }) => {
  // const { isAuthenticated } = useAuth();

  return true ? element : <Navigate to="/sign-in" />;
};

export default ProtectedRoute;
